<template>
    <div class="pageContainer">
        <el-row style="margin-top: 20px;">
            当前版本号：
            <el-input v-model="versionInfo.label" size="small" placeholder="版本号"
                      style="max-width: 200px; margin: 0 10px;"/>
            <el-button @click="dict_save" type="primary" plain size="small">保存</el-button>
        </el-row>

        <el-table border stripe :data="tableData" style="width: 100%; margin-top: 20px; color: #000">
            <el-table-column prop="name" label="表名称" width="200"></el-table-column>
            <el-table-column label="操作" min-width="150">
                <template slot-scope="scope">
                    <div class="flexStart">
                        <el-popconfirm title="确定进行初始化同步吗？" @confirm="onConfirm(scope.row)">
                            <el-tag slot="reference" type="danger" size="mini" style="margin-left: 10px;">同步</el-tag>
                        </el-popconfirm>
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>

</template>
<script>
export default {
    data() {
        return {
            fullscreenLoading: false,
            versionInfo: '',
            tableData: [
                {name: '组织架构', api:'api/init_office'},
                {name: '学生表', api:'api/import_student'},
                {name: '教师表', api:'api/import_teachers'},
                {name: '字典表', api:'api/sys_dict_save'},
                {name: '行业表', api:'api/initIndustry'},
                {name: '职位类别', api:'api/initJobType'},
                {name: '班级相册',api:'api/initClassImg'},
            ]
        };
    },
    mounted() {
        this.getVersion()
    },
    methods: {
        getVersion() {
            return new Promise(resolve => {
                this.$http.post('api/sys_dict_list', {dictType: ['version']}).then(res => {
                    if (res.data && res.data.length > 0) {
                        this.versionInfo = res.data[0]
                    }
                    resolve()
                })
            })
        },
        dict_save() {
            console.log(this.versionInfo)
            this.$http.post('api/dict_save', this.versionInfo).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('保存成功！')
                }
            })
        },
        onConfirm(e) {
            let apiUrl = e.api
            this.fullscreenLoading = true;
            this.$http.post(apiUrl).then((res) => {
                this.fullscreenLoading = false;
                this.$message.success( res.data.msg);
            });
        },
    },
};
</script>
<style type="text/less" lang="less">
.btns {
    .el-button {
        min-width: 200px;
        margin: 20px 0 0 0;
    }
}
</style>
